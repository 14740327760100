import { Box, makeStyles } from '@material-ui/core';
import React, { forwardRef } from 'react';

type StyledProps = {
  url: string;
  firstInBlock: boolean;
  colStart: number;
  lastInBlock: boolean;
  totalImages: number;
  index: number;
};

const useStyles = makeStyles(() => ({
  square: {
    width: '100%',
    backgroundImage: (props: StyledProps) => `url(${props.url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    gridColumn: (props: StyledProps) =>
      `${props.colStart + 1}/${props.colStart + 2}`,
    gridRow: (props: StyledProps) =>
      props.firstInBlock
        ? '1/3'
        : props.lastInBlock
          ? '2/3'
          : props.totalImages === props.index + 1
            ? '1/3'
            : '1/2',
  },
}));

type Props = {
  url: string;
  alt: string;
  index: number;
  totalImages: number;
};

const GalleryItem: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { url, alt, index, totalImages },
  forwardedRef
) => {
  const firstInBlock = index % 3 === 0;
  const lastInBlock = index % 3 === 2;
  const blockNumber = Math.floor(index / 3);
  const colStart = blockNumber * 2 + (firstInBlock ? 0 : 1);
  const classes = useStyles({
    url,
    firstInBlock,
    lastInBlock,
    colStart,
    totalImages,
    index,
  });

  return (
    <Box
      className={classes.square}
      role="img"
      aria-label={alt}
      {...{ ref: forwardedRef }}
    />
  );
};

export default forwardRef(GalleryItem);
